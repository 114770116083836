
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































































































$bp: xl;

.magazine-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 45rem;
  border-radius: 1.2rem;
  transform: none;

  @include mq($bp) {
    height: auto;
    min-height: 54rem;
    max-height: none;
  }
}

.magazine-card__content {
  max-width: 60rem;
  margin: 30% $spacing $spacing;
  padding: $spacing $spacing $spacing * 0.75;
  background: $c-white;
  border-radius: 6px;

  .magazine & {
    background: rgba($c-white, 0.7);
    border: 1px solid $c-white;
    backdrop-filter: blur(1rem);
  }

  @include mq($bp) {
    margin: 0 $spacing * 1.5 $spacing * 1.5;
    padding: $spacing * 1.5;
  }
}

.magazine-card__content__category {
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  .icon {
    flex-shrink: 0;
    width: 2rem;
    margin-right: $spacing * 0.25;
  }
}

.magazine-card__content__icon {
  color: $c-mustard;
}

.magazine-card__content__title {
  font-size: 2rem;
  line-height: 1.25;

  ::v-deep em {
    color: $c-dark-grey;
  }
}

.magazine-card__link {
  span {
    @extend %visually-hidden;
  }

  &::after {
    @include get-all-space;

    content: '';
    z-index: 2;
  }

  @media (pointer: fine) {
    &:focus-visible::after {
      border-radius: 4px;
      outline: 3px solid $c-dark-grey;
    }
  }
}

.magazine-card__icon {
  fill: $c-mustard;
  transition: transform 0.3s $ease-softer;

  @media (pointer: fine) {
    .magazine-card:hover & {
      transform: translate(1rem);
    }
  }
}

.magazine-card__video,
.magazine-card__picture {
  @include get-all-space;

  z-index: -1;

  video,
  .picture {
    @include image-fit;

    transition: transform 0.3s $ease-softer;
  }

  @media (pointer: fine) {
    .magazine-card:hover & {
      video,
      .picture {
        transform: scale(1.05);
      }
    }
  }
}

.magazine-card__picture__logo {
  @include center-xy;

  z-index: 9;
  padding: $spacing;
  background-color: $c-mustard;
  border-radius: 10rem;

  .icon {
    fill: $c-white;
  }

  @include mq($until: $bp) {
    top: 30%;
  }

  @include mq($bp) {
    .icon {
      width: 8rem;
      height: auto;
    }
  }
}

.magazine-card__corner {
  --corner-width: 11.6rem;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--corner-width);
  height: var(--corner-width);
  color: $c-medium-grey;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    border-right: var(--corner-width) solid transparent;
    border-bottom: var(--corner-width) solid transparent;
    border-left: var(--corner-width) solid $c-white;
    pointer-events: none;
  }

  &.new {
    color: $c-white;

    &::after {
      border-left-color: $c-mustard;
    }
  }
}

.magazine-card__corner__status {
  @extend %fw-bold;
  @extend %text-center;
  @extend %text-uppercase;

  width: 100%;
  padding: 0 1rem;
  font-size: 1.5rem;
  line-height: 1.1;
  transform: rotate(-45deg) translateY(-2rem);
}
